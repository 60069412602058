.cal-month-view .cal-header {
    text-align: center;
    font-weight: bolder;
}

.cal-month-view .cal-header .cal-cell {
    padding: 5px 0;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
}

.cal-month-view .cal-days {
    border: 1px solid;
    border-bottom: 0;
}

.cal-month-view .cal-cell-top {
    min-height: 78px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.cal-month-view .cal-cell-row {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
}

.cal-month-view .cal-cell {
    float: left;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.cal-month-view .cal-cell .cal-event {
    pointer-events: all !important;
}

.cal-month-view .cal-day-cell {
    min-height: 100px;
}
@media all and (-ms-high-contrast: none) {
    .cal-month-view .cal-day-cell {
        display: block;
    }
}

.cal-month-view .cal-day-cell:not(:last-child) {
    border-right: 1px solid;
}

.cal-month-view .cal-days .cal-cell-row {
    border-bottom: 1px solid;
}

.cal-month-view .cal-day-badge {
    margin-top: 18px;
    margin-left: 10px;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
}

.cal-month-view .cal-day-number {
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
}

.cal-month-view .cal-events {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 3px;
    line-height: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cal-month-view .cal-event {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.1;
    cursor: default;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.9em;
}

.cal-month-view .cal-open-day-events {
    padding: 15px;
}

.cal-month-view .cal-open-day-events .cal-event {
    position: relative;
    top: 2px;
}

.cal-month-view .cal-out-month .cal-day-badge,
.cal-month-view .cal-out-month .cal-event {
    opacity: 0.3;
}

.cal-month-view .cal-draggable {
    cursor: move;
}

.cal-month-view .cal-drag-active * {
    pointer-events: none;
}

.cal-month-view .cal-event-title {
    cursor: pointer;
}
.cal-month-view .cal-event-title:hover {
    text-decoration: underline;
}

.cal-month-view {
    background-color: #fff;
}
.cal-month-view .cal-cell-row:hover {
    background-color: #fafafa;
}
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: #ededed;
}
.cal-month-view .cal-days {
    border-color: #e1e1e1;
}
.cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: #e1e1e1;
}
.cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: #e1e1e1;
}
.cal-month-view .cal-day-badge {
    background-color: #b94a48;
    color: #fff;
}
.cal-month-view .cal-event {
    background-color: #1e90ff;
    border-color: #d1e8ff;
    color: #fff;
}
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #8b0000;
}
.cal-month-view .cal-day-cell.cal-today {
    background-color: #e8fde7;
}
.cal-month-view .cal-day-cell.cal-drag-over {
    background-color: #e0e0e0 !important;
}
.cal-month-view .cal-open-day-events {
    color: #fff;
    background-color: #555;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

.cal-week-view {
    /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-week-view * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.cal-week-view .cal-day-headers {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    padding-left: 70px;
    border: 1px solid;
}
.cal-week-view .cal-day-headers .cal-header {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    padding: 5px;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right: 1px solid;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
    border-left: 1px solid;
}
.cal-week-view .cal-day-headers span {
    font-weight: 400;
    opacity: 0.5;
}
.cal-week-view .cal-day-column {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-left: solid 1px;
}
.cal-week-view .cal-event {
    font-size: 12px;
    border: 1px solid;
}
.cal-week-view .cal-time-label-column {
    width: 70px;
    height: 100%;
}
.cal-week-view .cal-current-time-marker {
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 2;
}
.cal-week-view .cal-all-day-events {
    border: solid 1px;
    border-top: 0;
    border-bottom-width: 3px;
    padding-top: 3px;
    position: relative;
}
.cal-week-view .cal-all-day-events .cal-day-columns {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 0;
}
.cal-week-view .cal-all-day-events .cal-events-row {
    position: relative;
    height: 31px;
    margin-left: 70px;
}
.cal-week-view .cal-all-day-events .cal-event-container {
    display: inline-block;
    position: absolute;
}
.cal-week-view .cal-all-day-events .cal-event-container.resize-active {
    z-index: 1;
    pointer-events: none;
}
.cal-week-view .cal-all-day-events .cal-event {
    padding: 0 5px;
    margin-left: 2px;
    margin-right: 2px;
    height: 28px;
    line-height: 28px;
}
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.cal-week-view .cal-all-day-events .cal-time-label-column {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
}
.cal-week-view .cal-all-day-events .cal-resize-handle {
    width: 6px;
    height: 100%;
    cursor: col-resize;
    position: absolute;
    top: 0;
}
.cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
    right: 0;
}
.cal-week-view .cal-event,
.cal-week-view .cal-header {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cal-week-view .cal-drag-active {
    pointer-events: none;
    z-index: 1;
}
.cal-week-view .cal-drag-active * {
    pointer-events: none;
}
.cal-week-view .cal-time-events {
    position: relative;
    border: solid 1px;
    border-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
}
.cal-week-view .cal-time-events .cal-day-columns {
    display: -webkit-box;
    display: -ms-flexbox;
    -js-display: flex;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
.cal-week-view .cal-time-events .cal-day-column {
    position: relative;
}
.cal-week-view .cal-time-events .cal-events-container {
    position: relative;
}
.cal-week-view .cal-time-events .cal-event-container {
    position: absolute;
    z-index: 1;
}
.cal-week-view .cal-time-events .cal-event {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    margin: 1px;
    padding: 0 5px;
    line-height: 25px;
}
.cal-week-view .cal-time-events .cal-resize-handle {
    width: 100%;
    height: 4px;
    cursor: row-resize;
    position: absolute;
}
.cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
    bottom: 0;
}
.cal-week-view .cal-hour-segment {
    position: relative;
}
.cal-week-view .cal-hour-segment::after {
    content: '\00a0';
}
.cal-week-view .cal-event-container:not(.cal-draggable) {
    cursor: pointer;
}
.cal-week-view .cal-draggable {
    cursor: move;
}
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
    display: block;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed;
}
.cal-week-view .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center;
}
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
    display: none;
}
.cal-week-view .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.cal-week-view .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cal-week-view {
    background-color: #fff;
    border-top: solid 1px #e1e1e1;
}
.cal-week-view .cal-day-headers {
    border-color: #e1e1e1;
    border-top: 0;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
    border-right-color: #e1e1e1;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
    border-left-color: #e1e1e1;
}
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
    background-color: #ededed;
}
.cal-week-view .cal-day-column {
    border-left-color: #e1e1e1;
}
.cal-week-view .cal-event {
    background-color: #d1e8ff;
    border-color: #1e90ff;
    color: #1e90ff;
}
.cal-week-view .cal-all-day-events {
    border-color: #e1e1e1;
}
.cal-week-view .cal-header.cal-today {
    background-color: #e8fde7;
}
.cal-week-view .cal-header.cal-weekend span {
    color: #8b0000;
}
.cal-week-view .cal-time-events {
    border-color: #e1e1e1;
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #ededed;
}
.cal-week-view .cal-hour-odd {
    background-color: #fafafa;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
    background-color: #ededed;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom-color: #e1e1e1;
}
.cal-week-view .cal-current-time-marker {
    background-color: #ea4334;
}

.cal-day-view {
    /* stylelint-disable-next-line selector-type-no-unknown */
}
.cal-day-view mwl-calendar-week-view-header {
    display: none;
}
.cal-day-view .cal-events-container {
    margin-left: 70px;
}
.cal-day-view .cal-day-column {
    border-left: 0;
}
.cal-day-view .cal-current-time-marker {
    margin-left: 70px;
    width: calc(100% - 70px);
}

.cal-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: 11px;
    word-wrap: break-word;
    opacity: 0.9;
}

.cal-tooltip.cal-tooltip-top {
    padding: 5px 0;
    margin-top: -3px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
}

.cal-tooltip.cal-tooltip-right {
    padding: 0 5px;
    margin-left: 3px;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
}

.cal-tooltip.cal-tooltip-bottom {
    padding: 5px 0;
    margin-top: 3px;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
}

.cal-tooltip.cal-tooltip-left {
    padding: 0 5px;
    margin-left: -3px;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
}

.cal-tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
    border-radius: 0.25rem;
}

.cal-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
    border-top-color: #000;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
    border-right-color: #000;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
    border-bottom-color: #000;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
    border-left-color: #000;
}

.cal-tooltip-inner {
    color: #fff;
    background-color: #000;
}
