@buttonbackgroundColor: #007bc1;
@buttonbackgroundHoverColor: #635ae9;

@buttonAddbackgroundColor: #635ae9;
@buttonAddbackgroundHoverColor: #007bc1;

.btn.btn-fab-lg,
.btn.btn-fab-md,
.btn.btn-fab-spin-lg {
    background-color: @buttonbackgroundColor;
    border-color: @buttonbackgroundColor;
}

.btn.btn-fab-lg:hover,
.btn.btn-fab-md:hover,
.btn.btn-fab-spin-lg:hover {
    background-color: @buttonbackgroundHoverColor;
}

.btn-success,
.btn-success:active,
.btn-success:focus,
.btn-success:visited {
    background-color: @buttonbackgroundColor;
    border-color: @buttonbackgroundColor;
}

.btn-success:active:focus,
.btn-success:hover {
    background-color: @buttonbackgroundHoverColor;
    border-color: @buttonbackgroundHoverColor;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited {
    background-color: @buttonbackgroundColor;
    border-color: @buttonbackgroundColor;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: @buttonbackgroundHoverColor;
    border-color: @buttonbackgroundHoverColor;
}

.overlay-fa {
    position: relative;
    right: 5px;
    top: -5px;
}

.overlay-fa-invisible {
    visibility: hidden;
}

.top10 {
    margin-top: 10px;
}

.pointer {
    cursor: pointer;
}

.btn-add,
.btn-add:active,
.btn-add:focus,
.btn-add:visited {
    background-color: @buttonAddbackgroundColor;
    border-color: @buttonAddbackgroundColor;
    color: #fff;
}

.btn-add.active.focus,
.btn-add.active:focus,
.btn-add.active:hover,
.btn-add:active.focus,
.btn-add:active:focus,
.btn-add:active:hover,
.open > .dropdown-toggle.btn-add.focus,
.open > .dropdown-toggle.btn-add:focus,
.open > .dropdown-toggle.btn-add:hover {
    color: #fff;
    background-color: @buttonAddbackgroundHoverColor;
    border-color: @buttonAddbackgroundHoverColor;
}
